import { CreateOrUpdateEnEmploymentDetailsInput, CreateOrUpdateEnEmploymentDetailsOutput, CreateOrUpdateEnEmploymentsInput, CreateOrUpdateEnEmploymentsOutput, EnEmploymentDetailLinkDto, EnEmploymentDetailsDto, EnEmploymentOutput, EnEmploymentServiceProxy, EnMySironaOutput, GetEnOutput, MySironaEmploymentReadOnlyOutput } from './../../../shared/service-proxies/service-proxies';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, map, delay, switchMap } from 'rxjs/operators';
import {
    ApplicationServiceProxy,
    GeneralFileInfoDto,
    CreateOrUpdateEnContactInput,
    EnContactDto,
    EnContactServiceProxy,
    EnDto,
    EnServiceProxy,
    EnStatus,
    EnEmploymentDetailsServiceProxy,
    EnEmploymentDto
} from '@shared/service-proxies/service-proxies';
import { AppConsts } from '@shared/AppConsts';
import { ApplicationService } from '@app/shared/application/application.service';
import { CodeService } from '@app/shared/common/code/code.service';

@Injectable({
    providedIn: 'root',
})
export class EnService extends ApplicationService {

    en: EnDto;
    enResultDocuments: GeneralFileInfoDto[];
    onlyPrivateAddressAllowed = false;

    constructor(
        injector: Injector,
        private appService: ApplicationServiceProxy,
        codeService: CodeService,
        private enService: EnServiceProxy,
        private enContactService: EnContactServiceProxy,
        private enEmploymentService: EnEmploymentServiceProxy,
        private enEmploymentDetailsService: EnEmploymentDetailsServiceProxy
    ) {
        super(injector, codeService, appService);
    }

    get caseId(): string {
        return this.en.application.caseId;
    }

    get firstStep(): EnStatus {
        return this.en.firstStep;
    }

    get currentStep(): EnStatus {
        return this.en.currentStep;
    }

    get hasReadAndAcceptedTerms(): boolean {
        return this.en.hasReadAndAcceptedTerms;
    }

    getEn(caseId: string): Observable<GetEnOutput> {
        this.en = null;
        return this.getApplication(caseId).pipe(
            switchMap(() => this.enService.getEn(caseId).pipe(
                    tap((output: GetEnOutput) => {
                        this.en = output.en;
                    })
                ))
        );
    }

    getLatestSubmittedEnData(): Observable<EnMySironaOutput> {
        return this.enService.getLatestSubmittedEnData();
    }

    createEn(): Observable<string> {
        return this.enService.createEn();
    }

    withdrawEn(): Observable<void> {
        return this.enService.withdrawEn(this.caseId);
    }

    getEvaluationComments(): Observable<string> {
        return this.enService.getEvaluationComments(this._caseId);
    }

    getNextStatus(status: EnStatus): Observable<EnStatus> {
        return this.enService.getNextStatus(this._caseId, status);
    }

    getEnContact(): Observable<EnContactDto> {
        return of(this.en.enContact).pipe(delay(0));
    }

    acceptTerms() {
        return this.enService.acceptTerms(this.caseId);
    }

    enEmploymentDetailsProceed(): Observable<void> {
        return this.enEmploymentDetailsService.proceed(this.caseId).pipe(tap((status: EnStatus) => {
            this.en.currentStep = status;
        })).pipe(map((status: EnStatus) => null));
    }

    createOrUpdateEnEmploymentDetails(input: CreateOrUpdateEnEmploymentDetailsInput): Observable<void> {
        this.setStepToReview(EnStatus.EmploymentDetails);
        return this.enEmploymentDetailsService.createOrUpdateEnEmploymentDetails(input).pipe(tap((output: CreateOrUpdateEnEmploymentDetailsOutput) => {
            this.en.enEmploymentDetailsOutput.enEmploymentDetails = output.enEmploymentDetails;
            this.en.currentStep = output.status;
        })).pipe(map((status: CreateOrUpdateEnEmploymentDetailsOutput) => null));
    }

    createOrUpdateEnContact(input: CreateOrUpdateEnContactInput): Observable<void> {
        this.setStepToReview(EnStatus.Contact);
        return this.enContactService.createOrUpdateEnContact(input).pipe(tap((status: EnStatus) => {
            this.en.enContact = input.enContact;
            this.en.currentStep = status;
        })).pipe(map((status: EnStatus) => null));
    }

    getEmployments(): Observable<EnEmploymentOutput> {
        return of(this.en.enEmploymentOutput).pipe(delay(0));
    }

    getEmploymentsForEmploymentDetails(): Observable<EnEmploymentOutput> {
        const employmentOutput = new EnEmploymentOutput();
        const employments = this.en.enEmploymentOutput.employments.filter(x => x.dateOfEndOfEmployment == null);
        employmentOutput.employments = employments;
        employmentOutput.mobileEmployment = this.en.enEmploymentOutput.mobileEmployment;
        return of(employmentOutput).pipe(delay(0));
    }

    getEnEmploymentDetails(): Observable<EnEmploymentDetailsDto[]> {
        return of(this.en.enEmploymentDetailsOutput.enEmploymentDetails).pipe(delay(0));
    }

    getEnEmploymentDetailsForSummary(): Observable<EnEmploymentDetailsDto[]> {
        const markedEmploymentDetails = this.en.enEmploymentDetailsOutput.enEmploymentDetails.filter(x => x.isMarked === true);
        return of(markedEmploymentDetails).pipe(delay(0));
    }

    getEmploymentDetailsLinks(): Observable<EnEmploymentDetailLinkDto[]> {
        return this.enEmploymentDetailsService.getEmploymentDetailsLinks(this._caseId);
    }
    getLastSupplementalClaim(): Observable<string> {
        return this.enService.getLastSupplementalClaim(this._caseId);
    }

    getActiveEmploymentDetailLinks(): Observable<EnEmploymentDetailLinkDto[]> {
        return this.enEmploymentDetailsService.getActiveEmploymentDetailLinks(this._caseId);
    }

    getMySironaEmploymentAndLinksReadOnly(): Observable<MySironaEmploymentReadOnlyOutput> {
        return this.enEmploymentService.getMySironaEmploymentsReadOnly(this._caseId);
    }

   createOrUpdateEnEmployments(input: CreateOrUpdateEnEmploymentsInput): Observable<void> {
        this.setStepToReview(EnStatus.Employment);
        return this.enEmploymentService.createOrUpdateEnEmployments(input).pipe(tap((output: CreateOrUpdateEnEmploymentsOutput) => {
            this.en.enEmploymentOutput.employments = output.enEmployments;
            this.en.enEmploymentOutput.mobileEmployment = output.enMobileEmployment;
            this.en.enEmploymentDetailsOutput.enEmploymentDetails = [];
            this.en.currentStep = output.status;
        })).pipe(map((output: CreateOrUpdateEnEmploymentsOutput) => null));
    }

    releaseEn(): Observable<void> {
        return this.enService.releaseEn(this._caseId);
    }

    getStepUrl(nextStep: EnStatus): string {
        return this.getNextStep(nextStep);
    }

    getNextStep(nextStep: EnStatus): string {
        const baseUrl = AppConsts.applicantBaseUrl + `/en/${this.caseId}/wizard/`;
        const path = this.getUrlForStep(nextStep);
        return baseUrl + path;
    }

    isInEnStatus(expectedStatus: EnStatus): boolean {
        return this.en.currentStep === expectedStatus;
    }

    isEmploymentEmailAddressRedundant(employments: EnEmploymentDto[]): boolean {
        const email = this.en?.enContact?.email;
        const userEmail = this.en?.application?.userEmail;
        for (let i = 0; i < employments.length; i++) {
            if (employments[i].email === email || employments[i].email === userEmail) {
                return true;
            }
        }
        return false;
    }

    getUrlForStep(step: number): string {
        switch (step) {
            case EnStatus.Contact:
                return 'contact';
            case EnStatus.Employment:
                return 'employment';
            case EnStatus.EmploymentDetails:
                return 'employment-details';
            case EnStatus.Release:
                return 'release';
            case EnStatus.FinishReview:
                return 'finish-review';
            default:
                console.error('Invalid status for getUrlForStep');
                return null;
        }
    }

    private getPrivateAddressDescription(contact: EnContactDto) {
        let str = contact?.street;

        if (contact?.streetNr && contact.streetNr.length > 0) {
            str = str.concat(' ');
            str = str.concat(contact.streetNr);
        }

        str = str?.concat(', ');
        str = str?.concat(contact?.city);

        return str;
    }

    private getEmploymentAddressDescription(employment: EnEmploymentDto) {
        let str = employment?.businessName;

        if (employment.street.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(employment.street);

            if (employment.streetNr.length > 0) {
                str = str.concat(' ');
                str = str.concat(employment.streetNr);
            }
        }

        if (employment.city.length > 0) {
            if (str.length > 0) {
                str = str.concat(', ');
            }

            str = str.concat(employment.city);
        }

        return str;
    }

}
